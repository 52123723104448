@font-face {
    font-family: Lato;
    src: url(../../assets/fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: Rubik;
    src: url(../../assets/fonts/Rubik-Regular.ttf);
}

@font-face {
    font-family: Rubik-Bold;
    src: url(../../assets/fonts/Rubik-Bold.ttf)
}
.login-header {
    text-align: center;
}

.login-header-div {
    display: flex;
    padding: 10px 5% 0px;
}

.login-header-element-div {
    text-align: right;
    padding-top: 25px;
    width: 100%;
}

.login-header-element {
    display: inline-block;
    margin: 0px 15px;
    cursor: pointer;
}
.login-section {
    display: flex;
    padding: 15px 5% 0px;
}

.login-1 {
    width: 50%;
}

.login-2 {
    width: 50%;
}

.logo-startcups img {
    width: 220px;
    margin-bottom: 45px;
}

.login-titulo-upper{
    font-family: 'Rubik-bold';
    color: #FECD2F;
    font-size: 23px;
}
.login-titulo {
    font-family: 'Rubik';
    font-size: 49px;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 15px;
    letter-spacing: 1.5;
}
    .login-titulo strong{
        font-family: 'Rubik-bold';
        font-size: 36px;
        letter-spacing: .40px;
    }

    .login-titulo i {
        color: #FECD2F;
        font-size: 17px;
        margin-left: -2px;
    }

.login-titulo-text {
    color: #5F5F5F;
    line-height: 1.75;
    margin: -15px 0px 30px;
    width: 355px;
}

    .login-titulo span {
        font-family: 'Rubik-Bold';
    }

    .login-titulo span.yellow {
        color: #FECD2F;
    }

.login-form-group label {
    position: absolute;
    font-size: 10px;
    background-color: #ffffff;
    margin-top: -7px;
    margin-left: 7px;
}

input.login-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -4px 5px 12px #00000014;
    border: 1px solid #0000001F;
    border-radius: 3px;
    font-size: 13px;
    padding: 8px 12px;
    width: 304px;
}

    input.login-input:focus {
        outline: none;
        border: 1px solid #FECD2F !important;
    }

.login-description-alert {
    font-size: 14px;
    letter-spacing: 0px;
    color: red;
    opacity: 1;
    margin-top: 4px;
    margin-bottom: 10px;
}

i.fas.fa-eye {
    font-size: 15px;
    color: #00000099;
    position: absolute;
    margin-left: -30px;
    margin-top: 9px;
}

.login-btn {
    text-align: center;
    color: #263238;
    font-size: 12px;
    margin-top: 25px;
    background: #FECD2F 0% 0% no-repeat padding-box;
    box-shadow: -4px 4px 8px #00000014;
    border-radius: 4px;
    width: 310px;
    padding: 12px 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.login-password {
    font-size: 13px;
    letter-spacing: 0px;
    color: #454A53;
    text-align: center;
    width: 331px;
}

    .login-password span {
        color: #FECD2F;
    }

.login-social-text {
    text-align: center;
    font-size: 13px;
    margin-top: 30px;
    width: 330px;
}

.login-social-div {
    display: block;
    width: 330px;
    text-align: center;
}

.login-icon {
    display: inline-block;
}

    .login-icon img {
        width: 75px;
        cursor: pointer;
    }

.login-registrate {
    text-align: center;
    color: #5F5F5F;
    font-size: 13px;
    width: 330px;
    margin-top: 15px;
}

    .login-registrate span {
        color: #fecd2f;
        cursor: pointer;
    }

.login-img {
    background-image: url(../../assets/Login/banner-movil.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 17% 25% 13% 18%;
}

    .login-img img {
        width: 100%;
    }

    .login-img iframe {
        width: 100%;
        border-radius: 20px;
        height: 275px;
    }

.login-copyright {
    text-align: right;
    color: #5F5F5F;
    font-size: 13px;
    margin-top: 15px;
    width: 65%;
}

.login-copyright-div {
    padding: 0px 5%;
    display: flex;
    cursor: pointer;
}

    .login-copyright div {
        display: inline-block;
        margin-left: 5%;
    }

        .login-copyright div:nth-child(1) {
            margin-left: 0px;
        }

.login-registrate-div {
    width: 35%;
}
