@font-face {
    font-family: "Roboto-Medium";
    src: url("../../../assets/fonts/Roboto-Medium.ttf");
}
@font-face {
    font-family: "Roboto-Bold";
    src: url("../../../assets/fonts/Roboto-Bold.ttf");
}
.titulo{
    font-family: "Roboto-Medium";
    font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.01px;
  color: #292929;
}
.autor{
    font-family: "Roboto-Medium";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.03px;
    color: #848e9a;
    font-weight: 500;
}
.estrella{
    color: #ffbc52;
}
.valoracion{
    font-family: "Roboto-Medium";
    font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.17px;
  color: #242424;
}
.tema{
    font-family: "Roboto-Medium";
    font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.14px;
  color: #242424;
}