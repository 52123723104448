@font-face {
  font-family: "Roboto-Medium";
  src: url("../../../../assets/fonts/Roboto-Medium.ttf");
}

.title {
  font-family: "Roboto-Medium";
  font-size: 20px;
  font-weight: bold;
  font-stretch: narrower;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
  margin-bottom: 8px;
}
.subtitle {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
  opacity: 0.3;
  cursor: pointer;
}
.active {
  opacity: 1;
}

.line {
  width: auto;
  height: 1px;
  background-color: gainsboro;
  margin-top: -2px;
}

.rectangle {
  width: 177px;
  height: 6px;
  background-color: #fece01;
}

#rectPV {
  margin: 8px 0px 0.5px 0.5px;
}

#rectEP {
  margin: 8px 0px 0.5px 0px;
}

.hiddenNone {
  display: none;
}
.iconPV {
  opacity: 0.1;
  margin-left: 13px;
  margin-top: 15px;
  color: green;
}
.complete {
  opacity: 1;
  color: green;
}
.contador {
  font-family: "Roboto-Medium";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: gainsboro;
}

#PFQuestions > div > table,
th,
td {
  font-family: "Roboto-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
  height: 2rem;
  width: auto;
  border-width: 5px;
  border-color: white;
  background-color: rgb(240, 240, 240);
  border-collapse: collapse;
  padding: 0px;
}

.tabla {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
.interactive {
  cursor: pointer;
  background-color: rgb(254, 254, 173);
}

#inputID::placeholder {
  color: gainsboro;
  opacity: 1;
}
.numero{
  width: 70px;
  color: gray;
}
.nombre {
  width: 100px;
  color: gray;
}

.cuadroanio{
background-color: rgb(65, 64, 64);
}
.fftotales{
  background-color: rgb(229 231 235);
}
.accordion {
  overflow: hidden;
  padding: 0 1rem;
  transition: max-height 0.1s ease-out;
  height: auto;
  max-height: 600px;
}
.colapse {
  max-height: 0;
}
.todas{
  width: 950px;
}
.labeltable{
  left: 0;
  position: sticky;
  z-index: 9999;
  
}
.content{
  max-width: 180px;
}
.cantidades{
  width: 90px;
}

