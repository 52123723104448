@font-face {
  font-family: "Roboto-Medium";
  src: url("../../../../assets/fonts/Roboto-Medium.ttf");
}
.accordion{
  overflow:hidden;
  padding: 0 1rem;
  transition:max-height 0.1s ease-out; 
  height:auto;
  max-height:600px;
}
.colapse{
  max-height: 0;
}
.title{
  font-family: 'Roboto-Medium';
  font-size: 22px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
.subtitle{
  font-family: 'Roboto-Medium';
  font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
opacity: 0.6;
cursor: pointer;
}
.active{
  opacity: 1;
}

.rectangle{
  width: 100px;
  height: 6px;
  background-color: #fece01;
}

#rectBCM{
  margin: 8px 0px 0.5px 0.5px;
}



.hiddenNone{
  display: none;
}
.icon{
  opacity: .3;
  margin-left: 13px;
  color: green;
}
.complete{
  opacity: 1;
  color: green;
}
.contador{
  font-family: 'Roboto-Medium';
  font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
#LVQuestions > div > div >label {
font-family: 'Roboto-Medium';
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
border-color: gainsboro;
}

#LVQuestions input[type=radio] {
display: none;
}
#LVQuestions input[type=radio]:checked + .radioLCC  {
border-width: 2px;
border-color: #fece01;
border-radius: 6px;
cursor: pointer;
background-color: #fff5c7;
}

.radioLCC{
border-radius: 6px;
border-width: 1px;
border-color: grey;
cursor: pointer;
text-align: center;
width: 100%;
height: 100%;
}

.IdeasNumero{
  color: gainsboro;
  padding-top: 5px;
}

.lineamenuBMC{
  margin-top: -1px;
  margin-bottom: 10px;
  border-bottom: gray;
  border-top-width: 1px;
  }

  .lineabajaBMC{
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: gray;
    border-top-width: 1px;
    }
