@font-face {
  font-family: "Roboto-Medium";
  src: url("../../../../assets/fonts/Roboto-Medium.ttf");
}
.workbook{
  font-family: "Roboto-Medium";
  font-size: 15px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
padding-bottom: 15px;
}
.radios .radio{
font-family: "Roboto-Medium";
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.5px;
color: gray;
margin: 0px 43px 10px 8px;
opacity: 100;
cursor: pointer;
}
.radios input[type=radio] {
display: none;
}
.radios input[type=radio]:checked + .radio  {
font-family: "Roboto-Medium";
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: black;
padding: 6px 12px 8px 12px;
border-radius: 8px;
background-color: #fece01;
cursor: pointer;
}
.radios input[type=radio]:checked + .radio .icon {
color:#000
}


.radios{
border-right: solid 1px #F5F5F5;
}
.guardado{
left: 40.20%;
border-radius: 8px;
box-shadow: 0px -4px 12px 0 rgba(0, 0, 0, 0.12);
background-color: #F5F5F5;
padding-left: 57px;
padding-right: 57px;

}
.continuar{
font-family: "Roboto-Medium";
font-size: 14px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.18px;
color: #303030;
background-color:#F5F5F5;
padding-right: 35px;
padding-top: 23px;
}
.guardar{
font-family: "Roboto-Medium";
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
border-radius: 8px;
box-shadow: 0 2px 12px 0 rgba(48, 48, 48, 0.12);
background-color: white;
padding: 8px 9px 8px 11px;
margin-right: 10px;
margin-top: 15px;
margin-bottom: 10px;
}
.revision{
font-family: "Roboto-Medium";
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
border-radius: 8px;
background-color: #fece01;
padding: 8px 8px 8px 11px;
margin-top: 15px;
}
#titleproject{
  border: none !important;
    border-color: #fff !important;
    outline: none !important;
}