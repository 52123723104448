@font-face {
    font-family: "Roboto-Medium";
    src: url("../../../assets/fonts/Roboto-Medium.ttf");
}
.play{
  color: #fecd03;
}
.info{
    font-family: "Roboto-Medium";
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
}
.infocard{
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    background-color: #fff;
}
.ver{
    font-family: "Roboto-Medium";
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
