@font-face {
  font-family: "Roboto-Medium";
  src: url("../../assets/fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../../assets/fonts/Roboto-Bold.ttf");
}
.buttonactive {
  background-color: #fecd03;
  box-shadow: 0 2px 8px 0 rgba(254, 205, 3, 0.4);
  border-radius: 8px;
  font-size: 10px;
  font-family: "Roboto-Medium";
}
.buttontop {
  border-radius: 8px;
  font-size: 10px;
  font-family: "Roboto-Medium";
  border-radius: 8px;
  background-color: #fff;
  margin: 15px 16px 10px;
  color: #303030;
}
.buttontop1 {
  border-radius: 10px;
  font-size: small;
  font-family: "Roboto-Medium";
  border: solid 1px #e8ecf0;
  background-color: #d5dce6;
}
.titlenews {
  font-family: "Roboto-Bold";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
.seccion {
  font-family: "Roboto-Bold";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
.vertodas {
  font-family: "Roboto-Medium";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #303030;
}
.ordenar {
  font-family: "Roboto-Medium";

  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222221;
}
.select-ordenar {
  margin: 0 8px 0 14px;
  font-family: "Roboto-Medium";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #303030;
}
.flecha {
  color: #fecd03;
}
