@font-face {
    font-family: "Roboto-Bold";
    src: url("../../../assets/fonts/Roboto-Bold.ttf");
}
@font-face {
    font-family: "Roboto-Medium";
    src: url("../../../assets/fonts/Roboto-Medium.ttf");
}
.titulo{
    font-family: "Roboto-Bold";
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #303030;
}
.duracion{
    opacity: 0.8;
    font-family: "Roboto-Medium";
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #303030;
}
.empezar{
    font-family: "Roboto-Bold";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262625;
}