@font-face {
  font-family: "Roboto-Medium";
  src: url("../../../../assets/fonts/Roboto-Medium.ttf");
}

/*}.title{
  font-family: 'Roboto-Medium';
  font-size: 22px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
*/
.accordion{
  overflow:hidden;
  padding: 0 1rem;
  transition:max-height 0.1s ease-out; 
  height:auto;
  max-height:600px;
}
.colapse{
  max-height: 0;
}

.subtitle{
  font-family: 'Roboto-Medium';
  font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
opacity: 0.6;
cursor: pointer;
}
.active{
  opacity: 1;
}

.rectangle{
  width: 120px;
  height: 6px;
  background-color: #fece01;
}

#rectMU{
  margin: 8px 0px 0.5px 0px;
}

#rectME{
  margin: 8px 0px 0.5px 0px; 
}

#rectMA{
  margin: 8px 0px 0.5px 0px; 
}

#rectPV{
  margin: 8px 0px 0.5px 0px; 
}


/*
.rectangle{
  width: 50px;
height: 4px;
margin: 7px 204px 0.5px 24px;
background-color: #fece01;
}
*/

#rectMEup{
width: 1px;
height: 15px;
background-color: gainsboro;
margin-top: 10px;
margin-bottom: 10px;
}
#rectMEleft{
width: 40px;
height: 1px;
margin-top: 25px;
margin-right: 5px;
margin-left: 5px;
background-color: gainsboro;
}

.hiddenNone{
  display: none;
}
.iconDT{
  opacity: .10;
  margin-left: 13px;
  margin-top: 15px;
  color: gray;
}
.complete{
  opacity: 1;
  color: green;
}

.contador1{
font-family: 'Roboto-Medium';
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: gainsboro;
}


#MUQuestions > div > label {
font-family: 'Roboto-Medium';
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}

#MUQuestions > div > div > input {
font-family: 'Roboto-Medium';
font-size: 16px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: gray; 
border-width: 1px;
border-color: #E8E6E3;
border-radius: 5px;
padding-top: 15px;
padding-bottom: 15px;
}

#MEQuestions > div > label {
font-family: 'Roboto-Medium';
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}

#MEQuestions > div > div > input {
font-family: 'Roboto-Medium';
font-size: 16px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: gray; 
border-width: 3px;
border-color: #E8E6E3;
border-radius: 5px;
padding-top: 15px;
padding-bottom: 15px;
}

#MEQuestions > div > div {
  font-family: 'Roboto-Medium';
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}

#MEQuestions > div > div > div > input {
font-family: 'Roboto-Medium';
font-size: 16px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: gray; 
border-width: 1px;
border-color: #E8E6E3;
border-radius: 5px;
padding-top: 15px;
padding-bottom: 15px;
}



#MEQuestions > div > div >label {
font-family: 'Roboto-Medium';
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
border-color: gainsboro;
}



#MEQuestions input[type=radio] {
display: none;
}

#MEQuestions input[type=radio]:checked + .radioLCC  {
border-width: 1px;
border-color: #fece01;
border-radius: 6px;
cursor: pointer;
background-color: #fff5c7;
}


#MAQuestions > div > div > input {
font-family: 'Roboto-Medium';
font-size: 16px;
font-weight: 300;
}

.MAlabels {
font-family: 'Roboto-Medium';
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: black;
opacity: 0.8; 
border-width: 1px;
border-color: #E8E6E3;
border-radius: 5px;
}

#PVQuestions > div > label {
  font-family: 'Roboto-Medium';
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}

#PVQuestions > div > div > input {
  font-family: 'Roboto-Medium';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: gray; 
  border-width: 1px;
  border-color: #E8E6E3;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
}

#PVQuestions > div > div >label {
  font-family: 'Roboto-Medium';
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
color: #303030;
}
.MAradioLCC{
border-radius: 6px;
border-width: 1px;
border-color: gainsboro;
cursor: pointer;
text-align: center;
background-color: white;
}
#MAQuestions input[type=radio] {
display: none;
}
#MAQuestions input[type=radio]:checked + .MAradioLCC  {
border-width: 2px;
border-color: #fece01;
border-radius: 6px;
cursor: pointer;
background-color: #fff5c7;
}

#PVQuestions > div > div > div >label {
font-family: 'Roboto-Medium';
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
border-color: gainsboro;
}
#PVQuestions input[type=radio] {
display: none;
}
#PVQuestions input[type=radio]:checked + .radioLCC  {
border-width: 2px;
border-color: #fece01;
border-radius: 6px;
cursor: pointer;
background-color: #fff5c7;
}

#PVQuestions > div > div > div > div > input {
font-family: 'Roboto-Medium';
font-size: 16px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: gray; 
border-width: 1px;
border-color: #E8E6E3;
border-radius: 5px;
padding-top: 15px;
padding-bottom: 15px;
}

#inputID::placeholder {
color: gainsboro;
opacity: 1;
}

.radioLCC{
border-radius: 6px;
border-width: 1px;
border-color: white;
cursor: pointer;
text-align: center;
width: 100%;
height: 100%;
}

.radioLCB{
border-radius: 6px;
border-width: 1px;
border-color: white;
cursor: pointer;
text-align: center;
width: 100%;
height: 100%;
margin: 2px;
}

.lineamenuDT{
margin-top: -1px;
margin-bottom: 10px;
border-bottom: gray;
border-top-width: 1px;
}


.lineabajaDT{
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: gray;
  border-top-width: 1px;
  }

