@font-face {
    font-family: "Roboto-Bold";
    src: url("../../../assets/fonts/Roboto-Bold.ttf");
}
@font-face {
    font-family: "Roboto-Medium";
    src: url("../../../assets/fonts/Roboto-Medium.ttf");
}
.cuadro{
    padding: 10px 12px 6px;
    border-radius: 8px;
    border: solid 1px #f9fafb;
    background-color: #fff;
}
.name{
    font-family: "Roboto-Bold";
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222221;
}
.info{
    opacity: 0.6;
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222221;
}
.texto{
    
    opacity: 0.8;
    font-family: "Roboto-Medium";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #222221;
}
.reacciones{
    opacity: 0.8;
  font-family: "Roboto-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5e5e5e;
}