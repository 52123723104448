@font-face {
    font-family: "Roboto-Bold";
    src: url("../../../assets/fonts/Roboto-Bold.ttf");
}
@font-face {
    font-family: "Roboto-Medium";
    src: url("../../../assets/fonts/Roboto-Medium.ttf");
}
.titulo{
    font-family: "Roboto-Bold";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #262625;
}
.info{
    opacity: 0.8;
    font-family: "Roboto-Medium";
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262625;
}
.aplicar{
    padding: 9px 36px 10px 37px;
    border-radius: 17.5px;
    background-color: #fecd03;
    width: 130px;
    height: 35px;
    font-family: "Roboto-Medium";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262625;
}