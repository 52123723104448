@font-face {
  font-family: "Roboto-Medium";
  src: url("../../assets/fonts/Roboto-Medium.ttf");
}
.label_form{
  font-family: "Roboto-Medium";
  font-size: 20px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #565c64;
}
.input_form{
  width: 500px;
  margin: 12px 0 0;
padding: 11px 0 10px 18px;
border-radius: 4px;
border: solid 1px #c0c0c0;
}
.select_form{
  width: 500px;
  height: 50px;
  margin: 12px 0 0;
  padding: 7px 8px 6px 18px;
  border-radius: 4px;
  border: solid 1px #c0c0c0;
}
.activo_tab{
font-family: "Roboto-Medium";
font-size: 20px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
.tab{
opacity: 0.6;
font-family: "Roboto-Medium";
font-size: 16px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
.rectangulo{
width: 150px;
height: 3px;
margin-left: 30px;
margin-top: 8px;
margin-bottom: 0px;
border-radius: 1.5px;
background-color: transparent;
}

.hiddenNone{
display: none;
}
.submit{
padding: 10px 10px 10px 10px;
font-family: "Roboto-Medium";
background-color: #fecd03;
color: #fff;
font-size: 18px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 1px;
text-align: center;
border-radius: 3px;
}
.logo1{
font-family: "Roboto-Medium";
font-size: 18px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #262625;
}
.logo2{
font-family: "Roboto-Medium";
font-size: 14px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
.logo3{
opacity: 0.6;
font-family: "Roboto-Medium";
font-size: 10px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #262625;
}
.botonfile{
border-radius: 4px;
border: solid 1px #c0c0c0;
padding: 14px;
}
.dropdown1{
font-family: "Poppins-Medium";
font-size: 12px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: gray;
margin-top: 0px;
}
#miequipo1{
font-family: "Roboto-Bold";
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #000;
background-color: #f0f2f5;
}
.regresar{
font-family: "Roboto-Medium";
font-size: 12px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
.bggris{
background-color: #f0f2f5;
}
.separador{
border-top: none;
border-right: solid 3px #e8ecf0;

margin-top: 0px;

}
.ayuda{
font-family: "Roboto-Medium";
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
.ayudacard{
width: 234px;
border-radius: 8px;
box-shadow: 0 8px 12px 0 rgba(48, 48, 48, 0.12);
background-color: #fff;
padding: 10px;
margin-bottom: -5px;
}
.sobre{
font-family: "Roboto-Medium";
opacity: 0.6;
font-size: 16px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #303030;
}
.textoayuda{
opacity: 0.6;
font-family: "Roboto-Medium";
font-size: 10px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.45;
letter-spacing: 0.2px;
color: #303030;
}

.separador2{
border-top: none;
border-right: solid 44px #f0f2f5;
margin-right: 95px;
margin-top: 0px;
}
