.dropdown:hover .dropdown-menu {
    display: block;
}
@font-face {
    font-family: "Poppins-Bold";
    src: url("../../assets/fonts/Poppins-Bold.ttf");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../../assets/fonts/Poppins-Medium.ttf");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../../assets/fonts/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Roboto-Bold";
    src: url("../../assets/fonts/Roboto-Bold.ttf");
}
.prueba{
    fill: green;
}
  
.navoptions{
    display: block;
    padding-top: .913rem;
    padding-bottom: .613rem;
    margin-left: .75rem;
    margin-top: .5rem;
    width: 15rem;
}
.naviconsactive{
    display: inline;
    width: 2rem;
    height: 2rem;
    margin-left: 2.5rem;
}
.navicons{
    display: inline;
    margin-top: 0px;
    margin-bottom: 2px;
    width: 2rem;
    height: 2rem;
    margin-left: 2.5rem;
    margin-right: 8px;
}
.navoptionsactive{
    background-color: #464646;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: "Poppins-Bold";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  margin-left: 16px;
  margin-bottom: 12px;
  display: block;
  padding-top: .12rem;
  padding-bottom: .12rem;
  margin-top: .5rem;
  width: 18rem;
  border-radius: 9999px;
}
.sidemenu{
    background-color: #313131;
}
.sidemenu1{
    background-color: #313131;
}

.iconH{
    margin-left: 13px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.navoptions{
    opacity: .5;
  font-family: "Poppins-Regular";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: -20px;
  margin-left: 22px;
}
.separador{
    border-top: solid 2px #464646;
}
.dropdown{
    font-family: "Poppins-Medium";
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: gray;
    margin: -5px;
    margin-top: 0px;
    margin-left: 30px;
}

.dropdown-menu{
    font-family: "Poppins-Medium";
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: gray;
    margin-right: 10px;
}

#miequipo{
    font-family: "Roboto-Bold";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-top:20px;
  margin-left: 28px;
}
#buscador:focus{
    border: none !important;
    border-color: #fff !important;
    outline: none !important;
}
#buscador{
    border: none !important;
    border-color: #fff !important;
    outline: none !important;
}


