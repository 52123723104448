@font-face {
  font-family: "Roboto-Medium";
  src: url("../../../../assets/fonts/Roboto-Medium.ttf");
}
.accordion {
  overflow: hidden;
  padding: 0 1rem;
  transition: max-height 0.1s ease-out;
  height: auto;
  max-height: 600px;
}
.colapse {
  max-height: 0;
}
.title {
  font-family: "Roboto-Medium";
  font-size: 20px;
  font-weight: bold;
  font-stretch: narrower;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
  margin-bottom: 8px;
}
.subtitle {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
  opacity: 0.3;
  cursor: pointer;
}
.active {
  opacity: 1;
}

.line {
  width: auto;
  height: 1px;
  background-color: gainsboro;
  margin-top: -2px;
}

.rectangle {
  width: 177px;
  height: 6px;
  background-color: #fece01;
}

#rectSC {
  margin: 8px 0px 0.5px 0.5px;
}

#rectPI {
  margin: 8px 0px 0.5px 0px;
}

.hiddenNone {
  display: none;
}
.iconPV1 {
  opacity: 0.1;
  margin-left: 13px;
  margin-top: 15px;
  color: gray;
}
.complete {
  opacity: 1;
  color: green;
}
.contador {
  font-family: "Roboto-Medium";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: gainsboro;
}

#SCQuestions > div > div > label {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}

#PIQuestions > div > div > label {
  font-family: "Roboto-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
#APQuestions > div > div > label {
  font-family: "Roboto-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
#LCHQuestions > div > div > label {
  font-family: "Roboto-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
#CQuestions > div > div > label {
  font-family: "Roboto-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}
#AQuestions > div > div > label {
  font-family: "Roboto-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}

#inputID::placeholder {
  color: gainsboro;
  opacity: 1;
}

.lineamenuPV {
  margin-top: -1px;
  margin-bottom: 10px;
  border-bottom: gray;
  border-top-width: 1px;
}
