@font-face {
    font-family: "Roboto-Medium";
    src: url("../../assets/fonts/Roboto-Medium.ttf");
}
@font-face {
    font-family: "Roboto-Bold";
    src: url("../../assets/fonts/Roboto-Bold.ttf");
}
.buttonactive{
    background-color:#fecd03 ;
    box-shadow: 0 2px 8px 0 rgba(254, 205, 3, 0.4);
    border-radius: 8px;
    font-size: small;
    font-family: "Roboto-Medium";
}
.buttontop{
    border-radius: 8px;
    font-size: small;
    font-family: "Roboto-Medium";
    border-radius: 8px;
    background-color: #fff;
    margin: 15px 16px 10px;
    color: #303030;
}
.buttontop1{
  border-radius: 10px;
  font-size: small;
  font-family: "Roboto-Medium";
  border: solid 1px #e8ecf0;
  background-color: #d5dce6;
}
.titlenews{
    font-family: "Roboto-Bold";
    font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
}