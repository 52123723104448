@font-face {
    font-family: "Roboto-Medium";
    src: url("../../../assets/fonts/Roboto-Medium.ttf");
}
.figura{
    background-color: #fecd03;
    border-radius: 4px;
    padding: 5px;
}
.titulo{
    font-family: "Roboto-Medium";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    color: #1e1e1e;
}
.avance{
    opacity: 0.5;
    font-family: "Roboto-Medium";
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.13px;
    color: #1e1e1e;
}
